import moment from 'moment'

import { BRIEFING_TYPES } from 'constants/pib'

import { formatDMSObjectToString } from './mapHelpers'

export const calculateDefaultPibValidFrom = () =>
    moment().utc().format('YYYY-MM-DDTHH:mm')

export const calculateDefaultPibValidTo = (days) =>
    moment().add(days, 'days').utc().format('YYYY-MM-DDTHH:mm')

export const formatCheckboxByOrder = (selectedValues, options) => {
    if (selectedValues.length === 1) return selectedValues[0]

    const optionsArray = options
        .sort((a, b) => a.order - b.order)
        .map((option) => option.id)

    const sortedSelectedValues = optionsArray.filter((option) =>
        selectedValues.includes(option)
    )

    return sortedSelectedValues.join('')
}

const formatCoordinates = (coordinates) => {
    // Lat/Long (e.g. 51N001W, 5100N00100W)
    return {
        Latitude: {
            Direction: coordinates.substr(4, 1),
            Degree: Number(coordinates.substr(0, 2)),
            Minute: Number(coordinates.substr(2, 2)),
        },
        Longitude: {
            Direction: coordinates.substr(10, 1),
            Degree: Number(coordinates.substr(6, 2)),
            Minute: Number(coordinates.substr(8, 2)),
        },
    }
}

export const formatAerodromePIBRequest = (formData) => {
    const { id, BriefingType, Alternate, AlternateList, eadErrors, ...data } =
        formData

    return {
        ...data,
        BriefingType: formatCheckboxByOrder(BriefingType, BRIEFING_TYPES),
        AerodromeGeoFilter: {
            AerodromeList: AlternateList.map((code) => ({ Code: code })),
        },
        SortOrder: '1',
        Scope: 'A',
    }
}

export const formatAreaPIBRequest = (formData) => {
    const {
        id,
        BriefingType,
        FlightLevel: { LowerFL, UpperFL },
        AddFIRS,
        AddFIRSList,
        FIRSSearch,
        eadErrors,
        ...data
    } = formData

    return {
        ...data,
        BriefingType: formatCheckboxByOrder(BriefingType, BRIEFING_TYPES),
        FlightLevel: { LowerFL: Number(LowerFL), UpperFL: Number(UpperFL) },
        AreaGeoFilter: {
            FIRList: AddFIRSList.map((code) => ({ ICAO: code })),
        },
        SortOrder: '1',
        Scope: 'EW',
    }
}

export const formatRoutePIBRequest = (
    formData,
    alternateFIRMap,
    FirDepAerodrome,
    FirDestAerodrome
) => {
    const {
        id,
        DepAerodrome,
        DestAerodrome,
        AerodromeSearch,
        BriefingType,
        FlightLevel: { LowerFL, UpperFL },
        AddFIRS,
        AddFIRSList,
        FIRSSearch,
        Alternate,
        AlternateList,
        eadErrors,
        ...data
    } = formData

    const FIRList = [
        ...AddFIRSList.map((code) => ({ ICAO: code })),
        ...AlternateList.map((code) => ({ ICAO: alternateFIRMap[code] })),
    ]

    const FlightLevel = {
        LowerFL: Number(LowerFL),
        UpperFL: Number(UpperFL),
    }

    return {
        ...data,
        BriefingType: formatCheckboxByOrder(BriefingType, BRIEFING_TYPES),
        FlightLevel: { LowerFL: Number(LowerFL), UpperFL: Number(UpperFL) },
        RouteGeoFilter: {
            customFIRList: AddFIRSList.map((code) => ({ ICAOCode: code })),
            customAlternateList: AlternateList.map((code) => ({
                ICAOCode: code,
                FIRCode: alternateFIRMap[code],
            })),
            FIRList: [
                { ICAO: FirDepAerodrome[0].FIRCode },
                ...FIRList,
                { ICAO: FirDestAerodrome[0].FIRCode },
            ],
            aerodromeList: [
                { Code: DepAerodrome },
                { Code: DestAerodrome },
                ...AlternateList.map((code) => ({ Code: code })),
            ],
            AdditionalFIRs: FIRList,
            // RouteDescription: `${DepAerodrome} ${RouteDescription ? RouteDescription : ''} ${DestAerodrome}`,
            RouteDescription: `${DepAerodrome} ${DestAerodrome}`,
            AlternateList: AlternateList.map((code) => ({ Code: code })),
            routeRadiusAD: 5,
        },
        FirstFlightLevel: FlightLevel,
        OtherFlightLevel: FlightLevel,
        LastFlightLevel: FlightLevel,
        SortOrder: '1',
        Scope: 'AEW',
    }
}

export const formatNarrowRoutePIBRequest = (formData) => {
    const {
        id,
        DepAerodrome,
        DestAerodrome,
        AerodromeSearch,
        BriefingType,
        FlightLevel: { LowerFL, UpperFL },
        RouteWidth,
        RouteDescription,
        AddFIRS,
        AddFIRSList,
        FIRSSearch,
        Alternate,
        AlternateList,
        eadErrors,
        ...data
    } = formData

    const FlightLevel = {
        LowerFL: Number(LowerFL),
        UpperFL: Number(UpperFL),
    }

    return {
        ...data,
        BriefingType: formatCheckboxByOrder(BriefingType, BRIEFING_TYPES),
        FirstFlightLevel: FlightLevel,
        OtherFlightLevel: FlightLevel,
        LastFlightLevel: FlightLevel,
        NarrowRouteGeoFilter: {
            customFIRList:
                AddFIRSList.length > 0
                    ? AddFIRSList.map((code) => ({ ICAOCode: code }))
                    : null,
            FIRList:
                AddFIRSList.length > 0
                    ? AddFIRSList.map((code) => ({ ICAO: code }))
                    : null,
            AlternateADList:
                AlternateList.length > 0
                    ? AlternateList.map((code) => ({ Code: code }))
                    : null,
            RouteDescription: `${DepAerodrome} ${RouteDescription} ${DestAerodrome}`,
            RouteWidth: Number(RouteWidth),
            RouteRadiusAD: 5,
        },
        SortOrder: '1',
        Scope: 'AEW',
    }
}

export const formatPointPIBRequest = (formData) => {
    const {
        id,
        BriefingType,
        GeoCoordinates,
        Radius,
        FlightLevel: { LowerFL, UpperFL },
        eadErrors,
        ...data
    } = formData

    return {
        ...data,
        BriefingType: formatCheckboxByOrder(BriefingType, BRIEFING_TYPES),
        GeoCoordinates,
        AreaGeoFilter: {
            GeoCoordinateBasedArea: {
                Radius: Number(Radius),
                GeoCoordinate: formatCoordinates(GeoCoordinates),
            },
        },
        SortOrder: '1',
        Scope: 'EW',
        FlightLevel: { LowerFL: Number(LowerFL), UpperFL: Number(UpperFL) },
    }
}

export const formatPolygonPIBRequest = (formData) => {
    const {
        id,
        PolygonCoordinate,
        PolygonCoordinateList,
        BriefingType,
        FlightLevel: { LowerFL, UpperFL },
        eadErrors,
        ...data
    } = formData

    return {
        ...data,
        BriefingType: formatCheckboxByOrder(BriefingType, BRIEFING_TYPES),
        AreaGeoFilter: {
            PolygonBasedArea: {
                GeoCoordinate: PolygonCoordinateList.map((item) =>
                    formatCoordinates(item)
                ),
            },
        },
        SortOrder: '1',
        Scope: 'EW',
        FlightLevel: { LowerFL: Number(LowerFL), UpperFL: Number(UpperFL) },
    }
}

export const formattedGenericRoute = (RouteElement) => {
    let output = []

    if (RouteElement && RouteElement.length > 0) {
        RouteElement.forEach(({ SignificantPoint, GeoCoordinate }) => {
            if (SignificantPoint) {
                output.push(`${SignificantPoint.Code}`)
            }
            if (GeoCoordinate) {
                output.push(
                    formatDMSObjectToString(
                        GeoCoordinate.Latitude.Coordinate,
                        GeoCoordinate.Longitude.Coordinate
                    )
                )
            }
        })
    }
    return output.length > 0 ? output.join(' ') : ' DCT '
}

export const formatMilitaryDateTime = (rawDateTime) => {
    if (!rawDateTime) return ''
    if (rawDateTime === 'PERM') return rawDateTime
    return moment(rawDateTime, 'YYMMDDhhmm').format('Do MMM YYYY HH:mm')
}

const getPIBType = (key, pib) => {
    const type = key.replace('Filed', '').replace('PIBRequest', '')

    if (pib.isPoint) return 'Point'
    if (pib.isPolygon) return 'Polygon'

    return type
}

export const formatPIBResponse = (rawResponse) => {
    if (!rawResponse?.length) return []

    const preparedResponses = rawResponse.map((el) =>
        Object.keys(el).map((key) =>
            el[key].map((pib) => ({
                ...pib,
                type: getPIBType(key, pib),
            }))
        )
    )

    const mergedArrays = preparedResponses[0].reduce((a, b) => a.concat(b), [])
    return mergedArrays.sort((a, b) => {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.issuedDate.date) - new Date(a.issuedDate.date)
    })
}

export const formatAerodromePIBResponse = (data) => {
    if (!data) return null

    return {
        id: data.pibUid,
        ValidFrom: data.ValidFrom?.date,
        ValidTo: data.ValidTo?.date,
        MessageTypeList: data.MessageTypeList?.MessageType,
        BriefingType: data.BriefingType?.split(''),
        Traffic: data.Traffic,
        Purpose: data.Purpose,
        Alternate: data.Alternate,
        AlternateList: data.AerodromeGeoFilter?.AerodromeList?.Aerodrome.map(
            (item) => item.Code
        ),
    }
}

export const formatAreaPIBResponse = (data) => {
    if (!data) return null

    return {
        id: data.pibUid,
        ValidFrom: data.ValidFrom?.date,
        ValidTo: data.ValidTo?.date,
        MessageTypeList: data.MessageTypeList?.MessageType,
        BriefingType: data.BriefingType?.split(''),
        Traffic: data.Traffic,
        Purpose: data.Purpose,
        FlightLevel: data.FlightLevel,
        AddFIRS: data.AddFIRS,
        AddFIRSList: data.AreaGeoFilter?.FIRList?.FIR.map((item) => item.ICAO),
    }
}

export const formatRoutePIBResponse = (data) => {
    if (!data) return null

    return {
        id: data.pibUid,
        DepAerodrome: data.ADEP,
        DestAerodrome: data.ADES,
        ValidFrom: data.ValidFrom?.date,
        ValidTo: data.ValidTo?.date,
        MessageTypeList: data.MessageTypeList?.MessageType,
        BriefingType: data.BriefingType?.split(''),
        Traffic: data.Traffic,
        Purpose: data.Purpose,
        FlightLevel: data.FlightLevel,
        AddFIRS: data.AddFIRS,
        AddFIRSList: data.CustomFIRs?.map((item) => item.ICAOCode),
        Alternate: data.Alternate,
        AlternateList: data.CustomAlternate?.map((item) => item.ICAOCode),
    }
}

export const formatNarrowRoutePIBResponse = (data) => {
    if (!data) return null

    return {
        id: data.pibUid,
        DepAerodrome: data.ADEP,
        DestAerodrome: data.ADES,
        ValidFrom: data.ValidFrom?.date,
        ValidTo: data.ValidTo?.date,
        MessageTypeList: data.MessageTypeList?.MessageType,
        BriefingType: data.BriefingType?.split(''),
        Traffic: data.Traffic,
        Purpose: data.Purpose,
        FlightLevel: data.FlightLevel,
        RouteWidth: data.NarrowRouteGeoFilter?.RouteWidth,
        RouteDescription:
            data.narrowRouteElementList?.RouteElement?.length > 0
                ? formattedGenericRoute(
                      data.narrowRouteElementList.RouteElement
                  )
                :  data?.RouteDescription ?? null,
        AddFIRS: data.AddFIRS,
        AddFIRSList: data.NarrowRouteGeoFilter?.FIRList?.FIR?.map(
            (item) => item.ICAO
        ),
        Alternate: data.Alternate,
        AlternateList:
            data.NarrowRouteGeoFilter?.AlternateADList?.Aerodrome?.map(
                (item) => item.Code
            ),
    }
}

export const formatPointPIBResponse = (data) => {
    if (!data) return null

    return {
        id: data.pibUid,
        GeoCoordinates: data.GeoCoordinates,
        Radius: data.AreaGeoFilter?.GeoCoordinateBasedArea?.Radius,
        ValidFrom: data.ValidFrom?.date,
        ValidTo: data.ValidTo?.date,
        MessageTypeList: data.MessageTypeList?.MessageType,
        BriefingType: data.BriefingType?.split(''),
        Traffic: data.Traffic,
        Purpose: data.Purpose,
        FlightLevel: data.FlightLevel,
    }
}

export const formatPolygonPIBResponse = (data) => {
    if (!data) return null

    return {
        id: data.pibUid,
        PolygonCoordinate: data.PolygonCoordinate,
        PolygonCoordinateList:
            data?.AreaGeoFilter?.PolygonBasedArea?.GeoCoordinate?.map((item) =>
                formatDMSObjectToString(
                    item.Latitude.Coordinate,
                    item.Longitude.Coordinate
                )
            ),
        ValidFrom: data.ValidFrom?.date,
        ValidTo: data.ValidTo?.date,
        MessageTypeList: data.MessageTypeList?.MessageType,
        BriefingType: data.BriefingType?.split(''),
        Traffic: data.Traffic,
        Purpose: data.Purpose,
        FlightLevel: data.FlightLevel,
    }
}
