import React, { useContext, useEffect, useState } from 'react'

import ENTITY from 'constants/entities'
import useFetchData from 'hooks/useFetchData'
import { CurrentUserContext } from './CurrentUserContext'

export const DefaultSettingsContext = React.createContext()

const DefaultSettingsContextProvider = (props) => {
    const [menuDisplaySettings, setMenuDisplaySettings] = useState()
    const [defaultSettings, setDefaultSettings] = useState()
    const [cmsPages, setCmsPages] = useState([])
    const [preconfiguredPibs, setPreconfiguredPibs] = useState([])
    const [reloadPreconfiguredPibs, setReloadPreconfiguredPibs] = useState(false)

    const { currentUser } = useContext(CurrentUserContext)

    const { data: menuSettingsData } = useFetchData(
        ENTITY.MENU_DISPLAY_SETTING,
        {
            systemDefault: true,
        },
        currentUser?.id,
        currentUser !== null
    )

    const { data: defaultSettingsData } = useFetchData(
        ENTITY.DEFAULT_SETTING,
        {},
        currentUser?.id,
        currentUser !== null
    )

    const { data: cmsPagesData } = useFetchData(
        ENTITY.PAGE,
        {
            pagination: false,
        },
        currentUser?.id,
        currentUser !== null
    )

    const { data: preconfiguredPibsData } = useFetchData(
        ENTITY.PIB_TEMPLATE,
        {
            pagination: false,
            locale: currentUser?.locale
        },
        reloadPreconfiguredPibs && currentUser?.id,
        currentUser !== null,
    )

    useEffect(() => {
        if (currentUser?.locale) {
            setReloadPreconfiguredPibs(!reloadPreconfiguredPibs)
        }
    }, [currentUser])

    useEffect(() => {
        if (menuSettingsData.length > 0) {
            setMenuDisplaySettings(menuSettingsData[0])
        }
    }, [menuSettingsData])

    useEffect(() => {
        if (defaultSettingsData.length > 0) {
            setDefaultSettings(defaultSettingsData[0])
        }
    }, [defaultSettingsData])

    useEffect(() => {
        if (cmsPagesData) {
            setCmsPages(cmsPagesData)
        }
    }, [cmsPagesData])

    useEffect(() => {
        if (preconfiguredPibsData) {
            setPreconfiguredPibs(preconfiguredPibsData)
        }
    }, [preconfiguredPibsData])

    return (
        <DefaultSettingsContext.Provider
            value={{
                menuDisplaySettings,
                setMenuDisplaySettings,
                defaultSettings,
                setDefaultSettings,
                cmsPages,
                setCmsPages,
                preconfiguredPibs,
                setPreconfiguredPibs,
            }}
        >
            {props.children}
        </DefaultSettingsContext.Provider>
    )
}

export default DefaultSettingsContextProvider
