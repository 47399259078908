export const ROLES = {
    ROLE_ADMIN: { id: 1, name: 'ROLE_ADMIN', label: 'role.admin' },
    ROLE_MANAGER: { id: 2, name: 'ROLE_MANAGER', label: 'role.manager' },
    ROLE_USER: { id: 3, name: 'ROLE_USER', label: 'role.user' },
}

export const ALL_ROLES = [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER, ROLES.ROLE_USER]

export const ALERT_TYPES = {
    SUCCESS: 'success',
    ERROR: 'error',
    DEFAULT: 'default',
}

export const ICON_SIZE = {
    TINY: 16,
    SMALL: 20,
    MEDIUM: 32,
    LARGE: 48,
}

export const ALERT_POSITIONS = {
    CENTER: 'center',
    RIGHT: 'right',
}

export const INPUT_FILED_TYPE = {
    TEXT: 'text',
    PASSWORD: 'password',
    EMAIL: 'email',
    FILE: 'file',
    CHECKBOX: 'checkbox',
    NUMBER: 'number',
}

export const SELECT_VALUE_TYPE = {
    STRING: 'string',
    OBJECT: 'object',
}

export const BUTTON_TYPE = {
    BUTTON: 'button',
    SUBMIT: 'submit',
    RESET: 'reset',
}

export const BUTTON_STATUS = {
    PRIMARY: 'primary',
    PRIMARY_INVERSE: 'primaryInverse',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
    BASIC: 'basic',
    BLUE: 'blue',
}

export const SORT_TYPES = {
    ASC: 1,
    DESC: -1,
}

export const SORT_KEYS = {
    ASC: 'asc',
    DESC: 'desc',
}

export const TABLE_FILTER_TYPE = {
    TEXT: 'text',
    DATE_TIME: 'date-time',
    SELECT: 'select',
    CHECKBOX: 'checkbox',
}

export const MODAL_TYPES = {
    SMALL: '-small',
    CUSTOM: '-custom',
    FIXED: '-fixed',
    MAP: '-map',
}

export const GDPR_LOG_TYPES = {
    ACCEPTED: 'accepted',
    DELETED: 'deleted',
}

export const FILE_UPLOAD_TYPE = {
    IMAGE: 'image',
    FILE: 'file',
}


export const GROUP_STATUS = {
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    REMOVED: 'removed',
    LEFT: 'left',
}
